import React, {Fragment} from "react";
import Navbar from "../components/Navbar";

const HomeScreen = () =>{
    return(
        <Fragment>
            <Navbar/>
        </Fragment>
    )
}

export default HomeScreen;